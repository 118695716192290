/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import logo from "../../img/mana-logo.svg";


const EmailSend = ({setShowLoginModal,confirmLetterSend, setEmailSendModal}) => {
    const { t } = useTranslation();

    const openLoginModal = () => {
        setEmailSendModal(false)
        setShowLoginModal(true)
    }


    return <div className="overlay overlay--common active">
        <div className="overlay__bg"></div>
        <div className="modal modal--signup opened" style={{alignItems: 'center'}}>

            <a href="/videos" className="site-header__logo">
                <img src={logo} alt="logo"/>
            </a>

            <div className="settings-block__label settings-block__label--large" 
            style={{textAlign: 'center', marginBottom: 25,marginTop: 20, whiteSpace: 'pre-line'}}>
                {confirmLetterSend}
            </div>



            <a onClick={() => openLoginModal()} href="#" className="modal__link modal__link--login" style={{}}>
                {t('header.login')}
            </a>

        </div>
    </div>
}

export default EmailSend
