import { createGlobalState } from 'react-hooks-global-state';

const initialState = { 
  userData: null,
  showPaymentToast: {
    show: false,
    type: 'warning' // warning | error | success 
  }
};

export const { useGlobalState } = createGlobalState(initialState);