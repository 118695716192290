import axios from "axios";
import Cookies from "js-cookie";
import {api_url} from "../utils/utils";

const token = Cookies.get('token');

export const getDictionary = (setDictionary ) => {
    const token = Cookies.get('token');
    axios.get(api_url + 'dictionaries/', {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
        .then(function (response) {
            setDictionary(response.data)
        })
        .catch(function (error) {
            console.log('err',error.response);
        });

}


export const getDictionaryData = (setDictionariesData) => {
    const token = Cookies.get('token');

    axios.get(api_url + `dictionaries`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
        .then(function (response) {
            setDictionariesData(response.data)
        })
        .catch(function (error) {
            console.log('err',error);

        });

}
export const getDictionaryIdData = (id, setDictionaryData) => {
    const token = Cookies.get('token');

    axios.get(api_url + `dictionaries/${id}`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
        .then(function (response) {
            setDictionaryData(response.data)
        })
        .catch(function (error) {
            console.log('err',error);

        });

}

export const editDictionaryWord = (setDictionaryData, id, status) => {
    console.log('token editDictionaryWord', token, id, status)

    axios.patch(api_url + `dictionary-words/${id}/`, {
        status: status,
    },{
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        },

    })
        .then(function (response) {
            getDictionaryIdData(response.data.dictionary, setDictionaryData)
        })
        .catch(function (error) {
            console.log('err',error);

        });

}

export const deleteDictionary = ( id, setDictionary) => {


    if(id !== null ) {
        axios.delete(api_url + `dictionaries/${id}`,{
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                "Authorization": token,
            },

        })
            .then(function (response) {
                console.log('res getDictionaryDWord', response)

                getDictionary(setDictionary)

            })
            .catch(function (error) {
                console.log('err',error);

            });
    } else {

    }

}


export const deleteDictionaryWord = ( id, dictionaryId, setDictionaryData, setShowWordModal) => {

    axios.delete(api_url + `dictionary-words/${id}/`,{
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        },

    })
        .then(function (response) {
            console.log('res getDictionaryDWord', response)
                if(setDictionaryData && setShowWordModal !== undefined) {
                    getDictionaryIdData(dictionaryId, setDictionaryData)
                    setShowWordModal(false)
                }

        })
        .catch(function (error) {
            console.log('err',error);

        });

}

export const addDictionaryWords = (word, translation, source, original_language, translated_language) => {
  return axios.post(api_url + `dictionary-words/`, {
    word: word,
    translation,
    source,
    original_language,
    translated_language,
  }, {
      withCredentials: true,
      headers: {
          'Accept': 'application/json',
          "Authorization": token,
      }
  })
  .then(function (response) {
      console.log('res add word', response.data)
      return response
  })
  .catch(function (error) {
      console.log('err',error);
      return error.response
  });
};

export const getIsLikeWord = (dictionary_id, word) => {
  const token = Cookies.get('token');

  return axios.get(api_url + `dictionary-words/is_word_in_dictionary/`, {
      withCredentials: true,
      params: {
        dictionary_id,
        word
      },
      headers: {
          'Accept': 'application/json',
          "Authorization": token,
      }
  })
  .then(function (response) {
      console.log('res isliked', response.data)
      return response.data.detail
  })
  .catch(function (error) {
      console.log('err',error);
  });
};
