import {useState} from "react";
import {renderFlag} from "../utils/flags";
import {api_url} from "../utils/utils";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "../hooks/store";

const Series = ({episode, series,setSeries,}) => {
    const {t} = useTranslation();
    const [userData] = useGlobalState('userData')
    const [, setShowPaymentToast] = useGlobalState('showPaymentToast')
    const [showDescription, setShowDescription]  = useState(false)
    const isTrial = userData.status === 'trial'

    const handleDownload = (e, episodeId) => {
        if (isTrial) {
            e.preventDefault();
            setShowPaymentToast({ show: true, type: 'warning' })
        } else {
            fetch(api_url + `tv-shows/${episodeId}/download_episode?download_episode_token=${userData.download_episode_token}`)
            .then(response => {
                if (response.status === 403) {
                    sessionStorage.setItem('showPaymentToast', 'true');
                    setShowPaymentToast({ show: true, type: 'error' })
                    return;
                }

                window.location.href = response.url
            });
        }
    }

    return <li className="season-list__item season-list-item">
        <div className="season-list-item__content">
            <span onClick={() => series !== episode.episode_number ?  setSeries(episode.episode_number) : setSeries(undefined)} className="season-list-item__name-block video-open" >
                <span className="season-list-item__number">{episode.episode_number}
                </span>
                <span className="season-list-item__name">{episode.title} </span>
                {
                    episode?.is_watched && (
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3424 4.84812C15.3212 4.79938 14.8012 3.64625 13.6399 2.485C12.5624 1.40875 10.7112 0.125 7.99991 0.125C5.28866 0.125 3.43741 1.40875 2.35991 2.485C1.19866 3.64625 0.678659 4.7975 0.657409 4.84812C0.636042 4.89613 0.625 4.94808 0.625 5.00062C0.625 5.05317 0.636042 5.10512 0.657409 5.15312C0.678659 5.20125 1.19866 6.35437 2.35991 7.51562C3.43741 8.59188 5.28866 9.875 7.99991 9.875C10.7112 9.875 12.5624 8.59188 13.6399 7.51562C14.8012 6.35437 15.3212 5.20312 15.3424 5.15312C15.3638 5.10512 15.3748 5.05317 15.3748 5.00062C15.3748 4.94808 15.3638 4.89613 15.3424 4.84812ZM7.99991 9.125C6.03866 9.125 4.32616 8.41125 2.90928 7.00438C2.31533 6.41409 1.81272 5.73854 1.41803 5C1.81261 4.26158 2.31523 3.58622 2.90928 2.99625C4.32616 1.58875 6.03866 0.875 7.99991 0.875C9.96116 0.875 11.6737 1.58875 13.0905 2.99625C13.6846 3.58622 14.1872 4.26158 14.5818 5C14.1837 5.76312 12.1874 9.125 7.99991 9.125ZM7.99991 2.125C7.43129 2.125 6.87544 2.29362 6.40264 2.60952C5.92985 2.92543 5.56136 3.37445 5.34376 3.89979C5.12615 4.42512 5.06922 5.00319 5.18015 5.56088C5.29108 6.11858 5.5649 6.63086 5.96698 7.03293C6.36905 7.43501 6.88133 7.70883 7.43902 7.81976C7.99672 7.93069 8.57479 7.87376 9.10012 7.65615C9.62546 7.43855 10.0745 7.07006 10.3904 6.59726C10.7063 6.12447 10.8749 5.56862 10.8749 5C10.8739 4.23781 10.5707 3.50712 10.0317 2.96816C9.49279 2.42921 8.7621 2.12599 7.99991 2.125ZM7.99991 7.125C7.57962 7.125 7.16878 7.00037 6.81932 6.76687C6.46987 6.53338 6.1975 6.20149 6.03666 5.8132C5.87583 5.42491 5.83375 4.99764 5.91574 4.58543C5.99773 4.17322 6.20012 3.79458 6.49731 3.4974C6.79449 3.20021 7.17313 2.99782 7.58534 2.91583C7.99755 2.83384 8.42482 2.87592 8.81311 3.03676C9.2014 3.19759 9.53328 3.46996 9.76678 3.81941C10.0003 4.16887 10.1249 4.57971 10.1249 5C10.1249 5.56359 9.90102 6.10409 9.50251 6.5026C9.104 6.90112 8.56349 7.125 7.99991 7.125Z" fill="#999999"/>
                        </svg>
                    )
                }
               
            </span>
            <div className={showDescription ? 'season-list-item__description opened' : 'season-list-item__description'}>
                <p>
                    {episode.description}
                </p>
            </div>
            <button
                className="season-list-item__more"
                onClick={() => setShowDescription(!showDescription)}
            >
                {showDescription ? 'hide description' : 'show description'} 
            </button>
        </div>
        <div className="season-list-item__info">
            <div className="season-list-item__flags">
                {episode.episode_subtitles.map(item => {
                    return renderFlag(item, userData.download_subtitles_token, isTrial, t, setShowPaymentToast)
                })}  
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                 <div onClick={(e) => handleDownload(e, episode.id)}
                    className="season-list-item__flags season-list-item__download">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>

            {/* <div className="season-list-item__update">3 weeks ago</div> */}
        </div>
    </li>
}

export default Series
