import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';

export const MainContainer = ({ children }) => {
  const location = useLocation()
  
  if (location.pathname === '/successful-payment') {
      return  <Navigate to='/videos#successful-payment' />
  }

  return (
    <main>{children}</main>
  )
}
