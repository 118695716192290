import './App.css';
import Header from "./components/header";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Profile from "./Views/Profile/Profile";
import Footer from "./components/footer";
import {useEffect, useState} from "react";
import axios from "axios";
import {api_url} from "./utils/utils";
import Cookies from 'js-cookie';
import Dictionary from "./Views/Profile/Dictionary";
import Videos from "./Views/Videos";
import Serial from "./Views/Serial";
import MainPage from "./Views/MainPage";
import FAQ from "./Views/FAQ";
import Notifications from "./Views/Profile/Notifications";
import Subscription from "./Views/Profile/Subscription";
import Terms from "./Views/Terms";
import {getFavourites, getWatched} from "./api/serials";
import i18n from "i18next";
import 'react-tooltip/dist/react-tooltip.css'
import Policy from "./Views/Policy";
import { useGlobalState } from './hooks/store';
import { MainContainer } from './components/MainContainer';
import PaymentToast from './components/PaymentToast';
import { AuthGuard } from './components/AuthGuard';


function App() {

    const [userData, setUserData] = useGlobalState('userData')

    const [showLoginModal, setShowLoginModal] = useState(false)
    const [userLogged, setUserLogged ] = useState(false)
    const [currentLang, setCurrentLang] = useState('ru')
    const [favouriteSerials, setFavouriteSerials] = useState([])
    const [watchedSerials, setWatchedSerials] = useState([])


    useEffect(() => {
        const token = Cookies.get('token');

        if(localStorage.getItem('userLogged') === 'true' && token ) {
                  setShowLoginModal(false)
                  getUserData(token)
                  getFavourites(token,setFavouriteSerials)
                  getWatched(token,setWatchedSerials)
        }

    }, [])


    useEffect(() => {
        if( localStorage.getItem('language')) {
            const lang  = localStorage.getItem('language')
            console.log('lang', lang)
            setCurrentLang(lang)
            changeLanguage(lang)
        } else {
            changeLanguage(currentLang)
        }

    }, [currentLang])

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const getUserData = (token, bearer) => {

        axios.get(api_url + 'users/me/', {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                "Authorization": token,
            }
        })
            .then(function (response) {

                setShowLoginModal(false)
                localStorage.setItem('userLogged', 'true')
                localStorage.setItem('userData', JSON.stringify(response.data))
                setCurrentLang(response.data.interface_language)
                setUserLogged(true)
                setUserData(response.data)
            })
            .catch(function (error) {
                console.log('err',error.response.data);

            });

    }

  return (
    <div >
        <Router>
            <Header showLoginModal={showLoginModal}
                    setShowLoginModal={setShowLoginModal}
                    getUserData={getUserData}
                    userData={userData}
                    setUserData={setUserData}
                    userLogged={userLogged}
                    favouriteSerials={favouriteSerials}
                    setFavouriteSerials={setFavouriteSerials}
                    watchedSerials={watchedSerials}
                    setWatchedSerials={setWatchedSerials}
                    getFavourites={getFavourites}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
            />
            <MainContainer>
                <PaymentToast />

                <Routes>
                    <Route 
                        path="/videos" 
                        element={ 
                            <AuthGuard>
                                <Videos setFavouriteSerials={setFavouriteSerials}
                                    getFavourites={getFavourites}
                                    getWatched={getWatched}
                                    favouriteSerials={favouriteSerials}
                                    setWatchedSerials={setWatchedSerials}
                                />
                            </AuthGuard>
                        }
                    />
                    
                    <Route 
                        path="/settings"
                        element={
                            <AuthGuard>
                                <Profile
                                    userLogged={userLogged}
                                    userData={userData}
                                    setUserData={setUserData}
                                />
                            </AuthGuard>
                        }
                    />

                    <Route  path={'/videos/:id'} element={ 
                        <AuthGuard>
                            <Serial  
                                currentLang={currentLang}
                                favouriteSerials={favouriteSerials}   
                            />
                        </AuthGuard>
                    }/>
                    <Route  path="/subscription" element={
                        <AuthGuard>
                            <Subscription currentLang={currentLang} />
                        </AuthGuard>
                    } />
                    <Route  path="/notifications" element={
                        <AuthGuard>
                            <Notifications />
                        </AuthGuard>
                    } />
                    <Route path="/dictionary" element={ 
                        <AuthGuard>
                            <Dictionary currentLang={currentLang} />
                        </AuthGuard>
                    } />

                    <Route path="/" element={ <MainPage userLogged={userLogged} />} />
                    <Route  path="/faq" element={ <FAQ />} />
                    <Route  path="/terms" element={ <Terms />} />
                    <Route  path="/policy" element={ <Policy />} />
                </Routes>
                
            </MainContainer>
            <Footer />
        </Router>
    </div >
  );
}

export default App;
