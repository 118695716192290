import axios from "axios";
import { api_url } from "../utils/utils";
import Cookies from "js-cookie";
import dayjs from "dayjs";

export const getSubscription = (setSubscription) => {
  const token = Cookies.get('token');

  axios.get(api_url + `payments/`, {
      headers: {
          'Accept': 'application/json',
          "Authorization": token,
      }
  })
      .then(function (response) {
        const subscriptionSort = response.data?.sort((a, b) => a.amount_of_month - b.amount_of_month)
        setSubscription(subscriptionSort)
      })
      .catch(function (error) {
          console.log('err', error);
      });
}


export const getPaymentHistory = (setPaymentHistory) => {
  const token = Cookies.get('token');

  axios.get(api_url + `payments/payment_history/`, {
      headers: {
          'Accept': 'application/json',
          "Authorization": token,
      }
  })
  .then(function (response) {
    const subscriptionSort = response.data?.sort((a, b) => {
      const dateA = dayjs(a.created_at);
      const dateB = dayjs(b.created_at);
      return dateB - dateA; 
    });

    setPaymentHistory(subscriptionSort)
  })
  .catch(function (error) {
      console.log('err', error);
  });
}

export const addPayment = (paymentId) => {
  const token = Cookies.get('token');

  axios.post(api_url + `payments/create_checkout_session/`, {
      payment: paymentId
    }, {
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
      .then(function (response) {
        window.location.href = response.data.data; 
      })
      .catch(function (error) {
          console.log('err', error);
      });
}