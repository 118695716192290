import { Tooltip as ReactTooltip } from 'react-tooltip'
import { api_url } from './utils'
import czIcon from '../img/flags/cz.png'
import frIcon from '../img/flags/fr.png'
import deIcon from '../img/flags/de.png'
import enIcon from '../img/flags/en.png'
import itIcon from '../img/flags/it.png'
import ruIcon from '../img/flags/ru.png'
import esIcon from '../img/flags/es.png'
import plIcon from '../img/flags/pl.png'
import ptIcon from '../img/flags/pt.png'
import trIcon from '../img/flags/tr.png'


const downloadEmployeeData = (file, downloadSubtitlesToken, isTrial, setShowPaymentToast) => {
    if (isTrial) {
        sessionStorage.setItem('showPaymentToast', 'true');
        setShowPaymentToast({ show: true, type: 'warning' })
        return;
    } 

    fetch(api_url + `tv-shows/${file.id}/download_subtitles?download_subtitles_token=${downloadSubtitlesToken}`)
        .then(response => {
            if (response.status === 403) {
                sessionStorage.setItem('showPaymentToast', 'true');
                setShowPaymentToast({ show: true, type: 'error' })
                return;
            }

            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let name = file.subtitles_file.replace(`${api_url}media/subtitles/`, '');
                let a = document.createElement('a');
                a.href = url;
                a.download = name;
                a.click();
            });
            //window.location.href = response.url;
        });
}



export const renderFlag =  (item, downloadSubtitlesToken, isTrial, t, setShowPaymentToast) => {
    const lang = item.language

    switch (lang) {
        case 'en':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={enIcon} alt='en'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'ru':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={ruIcon} alt='ru'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'es':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={esIcon} alt='es'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'cs':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={czIcon} alt='cz'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'fr':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={frIcon} alt='fr'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'de':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={deIcon} alt='de'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'it':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={itIcon} alt='it'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'pl':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={plIcon} alt='pl'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'pt':
           return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={ptIcon} alt='pt'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        case 'tr':
            return <div data-tooltip-id={`subtitles-download-${item.id}`} onClick={() => downloadEmployeeData(item, downloadSubtitlesToken, isTrial, setShowPaymentToast)} style={{cursor: 'pointer'}}>
                <img src={trIcon} alt='tr'/>
                <ReactTooltip
                    id={`subtitles-download-${item.id}`}
                    content={t(`flag.${item.language}`)}
                    place="bottom"
                />
            </div>
        default:
            return null
    }
}
