import {Link, useNavigate} from "react-router-dom";


export const SearchSerial = ({serials, onClose}) => {
    const navigate = useNavigate()
    const onCloseSearch = (e, link) => {
        e.preventDefault()
        onClose()
        navigate(link)
    };

    return <ul className="site-search__list">
            {serials?.map(item => {
                return  <li className="site-search__item site-search-item">
                    <Link 
                        to='#'
                        state={{
                            serialId: item.id,
                        }}
                        className="site-search-item__link"
                        onClick={(e) => onCloseSearch(e, `/videos/${item.id}`)}
                    >
                        <img className="site-search-item__img" src={item.thumbnail}
                             alt="New iron man series"/>
                        <span className="site-search-item__name">{item.title}</span>
                    </Link>
                </li>
            })}

    </ul>
 }
