import React, { useEffect, useState } from 'react';
import videojs from 'video.js';
import Cookies from "js-cookie";
import { CustomVideoPlayer } from '../video-player/js/main';

import {addDictionaryWords, deleteDictionaryWord} from '../../api/dictionary';
import addToDictionaryIcon from "../video-player/source/svg/icon-like.svg";
import { useGlobalState } from '../../hooks/store';
import { getWatchedLimits } from '../../api/serials';
import { Spinner } from 'react-activity';

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const customVideoPlayerRef = React.useRef(null);
  const watchTimeIntervalRef = React.useRef(null);
  const [, setShowPaymentToast] = useGlobalState('showPaymentToast')
  const [userData] = useGlobalState('userData')

  const [totalWatchTime, setTotalWatchTime] = useState(0);

  const {options, season, onReady, serialId, loading} = props;
  const isTrial = userData.status === 'trial'
  const maxWatchTime = 30 * 60; 
  const maxTranslationTrialCount = 30; // Maximum free translation

  const showPaymentToast = (type) => {
    if (isTrial) {
      if (type === 'free-translate') {
        const count = (+localStorage.getItem('translation-trial-count') || 0) + 1;
        if (count >= maxTranslationTrialCount) {
          setShowPaymentToast({ show: true, type: 'warning' });
          return true;
        }
        localStorage.setItem('translation-trial-count', count);
      }
    } else {
      localStorage.setItem('translation-trial-count', 0);
    }
  
    return false;
  };

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);

        player.on('play', () => {
          if (!watchTimeIntervalRef.current) {
            watchTimeIntervalRef.current = setInterval(() => {
              setTotalWatchTime(prevTime => prevTime + 1);
            }, 1000);
          }
        });

        player.on('pause', () => {  
          if (watchTimeIntervalRef.current) {
            clearInterval(watchTimeIntervalRef.current);
            watchTimeIntervalRef.current = null;
          }
        });
      });

      const customVideoPlayer = new CustomVideoPlayer(player, videojs, showPaymentToast);
      customVideoPlayerRef.current = customVideoPlayer;

      customVideoPlayer.initialize();

    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    player.ready(function() {
      const promise = player.play();

      if (promise !== undefined) {
        promise.then(function() {
        }).catch(function(error) {
          console.log('Autoplay was prevented => ', error);
        });
      }
    });

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);


  React.useEffect(() => {
    if (options.tracks) {
      customVideoPlayerRef.current.subtitleInitialize(options.tracks);
    }
  }, [options.tracks]);

  React.useEffect(() => {
    if (season) {
      customVideoPlayerRef.current.seasonInitialize(season);
    }
  }, [season]);

  useEffect(() => {
    const player = playerRef.current;
    const totalWatch = parseInt(Cookies.get('total-watch-time'), 10) || 0;
    if (isTrial && ((totalWatchTime >= maxWatchTime) || ((userData.watch_time_limits ?? 0) >= maxWatchTime))) {
      setShowPaymentToast({ show: true, type: 'warning' })

      player.pause();
      clearInterval(watchTimeIntervalRef.current);
      watchTimeIntervalRef.current = null;

      getWatchedLimits(totalWatchTime)
    }

    if (totalWatchTime === maxWatchTime) {
      const now = new Date().getTime();
      Cookies.set('last-reset-time', now);
    }
    
    if (totalWatchTime > totalWatch) {
      Cookies.set('total-watch-time', totalWatchTime);
    }
   
  }, [totalWatchTime]);

  React.useEffect(() => {
    customVideoPlayerRef.current.handleTranslationDictionaryClick({onClick: async ({
      word, translation, isChecked,isLiked,likeButtonIcon, translated_language, original_language
    }) => {
      if (isLiked.id) {
        deleteDictionaryWord(isLiked.id, false,)
        likeButtonIcon.src =  addToDictionaryIcon
      } else {
         const response = await addDictionaryWords(word, translation, serialId, original_language, translated_language)
         if (response.status === 403) {
          likeButtonIcon.src =  addToDictionaryIcon
          setShowPaymentToast({ show: true, type: 'warning' })
         }
      }
    }});

    const totalWatch = parseInt(Cookies.get('total-watch-time'), 10) || 0;
    const lastReset = Cookies.get('last-reset-time');
    const now = new Date().getTime();

    if (!lastReset || (now - parseInt(lastReset, 10)) > 24 * 60 * 60 * 1000) {
      setTotalWatchTime(0);
      Cookies.set('last-reset-time', now);
    } else {
      setTotalWatchTime(totalWatch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="wrapper">
      {
        loading && (
          <div id='video-spinner'>
            <Spinner size={45} speed={0.9} color="#fff" />
          </div>
        )
      }
      <div data-vjs-player>
        <div ref={videoRef} />
      </div>
    </div>

  );
}

export default VideoJS;
