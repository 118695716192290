import axios from "axios";
import {api_url} from "../utils/utils";
import Cookies from "js-cookie";


const token = Cookies.get('token');
export const getSerials = ({setSerials, serials, next}, Token, sortFilter, genresFilter, countryFilter, channelFilter, yearFilter, titleFilter) => {
    const nextUrl = next ? next.replace(api_url, '') : undefined
    let url = nextUrl ?? `tv-shows/?`

    const addFilterToUrl = (filterName, filterValue) => {
        if (filterValue && (filterValue !== 'Choose Option' && filterValue !== 'Выберите опцию')) {
            const hasValueRevert = ["year", "rating"].some((value) => filterValue.includes(value))
            if (filterName === "ordering" && hasValueRevert) {
                url += `&${filterName}=-${filterValue}`;
                return
            }
            url += `&${filterName}=${filterValue}`;
        }
    };

    addFilterToUrl('ordering', sortFilter);
    addFilterToUrl('year', yearFilter);
    addFilterToUrl('countries', countryFilter);
    addFilterToUrl('networks', channelFilter);
    addFilterToUrl('genres', genresFilter);
    addFilterToUrl('title', titleFilter);

    axios.get(api_url + url, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            const newSerials = {
                ...response.data,
                results: nextUrl ? serials.concat(response.data.results) : response.data.results
            } 
            setSerials(newSerials)
        })
        .catch(function (error) {
            console.log('err', error.response);

        });

}

export const getCountriesFilter = (setCountries, Token) => {
    axios.get(api_url + `tv-show-countries/`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setCountries(response.data)
        })
        .catch(function (error) {
            console.log('err', error);

        });
}

export const getGenresFilter = (setGenres, Token) => {
    axios.get(api_url + `tv-show-genres/`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setGenres(response.data)
        })
        .catch(function (error) {
            console.log('err', error);

        });
}
export const getYearsFilter = (setYears, Token) => {
    axios.get(api_url + `tv-show-years/`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setYears(response.data)
        })
        .catch(function (error) {
            console.log('err', error);
        });
}


export const getSerialData = (id, setSerialData, Token) => {
    console.log('token', token, id)

    axios.get(api_url + `tv-shows/${id}`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setSerialData(response.data)
        })
        .catch(function (error) {
            console.log('err', error);

        });

}
export const downloadSerial = (id, Token) => {
    console.log('token', token, id)

    axios.get(api_url + `tv-shows/${id}/download_episode`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
           console.log('res download', response)
        })
        .catch(function (error) {
            console.log('err', error);

        });

}


export const addToFavourites = (e, serial, setInFavourite, Token, inFavourite, inFavouriteId, setInFavouriteId) => {
    e.preventDefault()

    if (inFavourite) {
        deleteFavourites(e, inFavouriteId, setInFavourite, Token)
        return
    }

    axios.post(api_url + `user-favourites/`, {
        tv_show: serial.id,
    }, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setInFavouriteId(response.data.id)
            setInFavourite(true)

        })
        .catch(function (error) {
            console.log('err', error);
            if (error.status === 400) {
                deleteFavourites(e, serial.id, setInFavourite, Token)
            }
        });
}

export const getFavourites = (Token, setFavouriteSerials) => {
    axios.get(api_url + `user-favourites/`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setFavouriteSerials(response.data)
        })
        .catch(function (error) {
            console.log('err', error);

        });
}

export const getWatched = (Token, setWatchedSerials) => {
    axios.get(api_url + `user-watching/`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            setWatchedSerials(response.data)
        })
        .catch(function (error) {
            console.log('err', error);
        });
}

export const addToWatched = (id) => {
    const token = Cookies.get('token');
    axios.post(api_url + `user-watching/`, {
        tv_show: id,
    }, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
        .then(function (response) {
                console.log('addTo watching res', response)
        })
        .catch(function (error) {
            console.log('err addTo watching', error);

        });
}

export const addUserWatched = (id) => {
    const token = Cookies.get('token');
    axios.post(api_url + `user-watched/`, {
        episode: id,
    }, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
    .catch(function (error) {
        console.log('err addToWatched', error);
    });
}

export const deleteFavourites = (e, id, setInFavourite, Token,) => {
    console.log('token', token)
    axios.delete(api_url + `user-favourites/${id}`, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": Token ? Token : token,
        }
    })
        .then(function (response) {
            console.log('res SerialData', response)

            setInFavourite(false)
        })
        .catch(function (error) {
            console.log('err', error);

        });
}

export const getWatchedLimits = (watchTime, setWatchedLimits) => {
    const token = Cookies.get('token');

    axios.post(api_url + `user-watching-limits/`, {
        watch_time: watchTime
    }, {
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            "Authorization": token,
        }
    })
        .then(function (response) {
            setWatchedLimits(response.data)
        })
        .catch(function (error) {
            console.log('err', error);
        });
}