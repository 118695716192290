import {Navigate, useLocation} from 'react-router-dom';
import Cookies from 'js-cookie';

export const AuthGuard = ({ children }) => {
    const {pathname} = useLocation();

    const token = Cookies.get('token');

    if (!token && pathname !== '/') {
        return <Navigate to={'/#auth'} />
    }

    return children;
};
