/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from "react";
import axios from "axios";
import {api_url} from "../../utils/utils";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { SocialLogin } from '../SocialLogin';

const Login = ({
                   setShowLoginModal,
                   setShowSignModal,
                   setShowPasswordModal,
                   email,
                   setEmail,
                   password,
                   setPassword,
                   LoginUser,
                   validPassword,
                   getUserData
               }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const signUp = () => {
        setShowLoginModal(false)
        setShowSignModal(true)
    }

    const forgotPass = () => {
        setShowLoginModal(false)
        setShowPasswordModal(true)
    }

    const handleVkResponse = (code) => {
        if (code) {
            axios.post(api_url + `vk-auth/?code=${code}`)
            .then(function (response) {
                if (response.data.detail) {
                    Cookies.set('token', 'Token ' + response.data.detail);
                    localStorage.setItem('authType', 'token');
                    getUserData('Token ' + response.data.detail)
                    navigate('/videos')
                }
            })
        } else {
            window.location.href = 'https://oauth.vk.com/authorize?client_id=51927808&display=page&redirect_uri=https://manamana.tv/videos&scope=email&response_type=code&v=5.199';
        }
    }

    function removeHash() {
        navigate(window.location.pathname);
    }

    function getQueryParams(search) {
        const params = {};
        const queryString = search.substring(1);
        const regex = /([^&=]+)=([^&]*)/g;
        let m;
        while (m = regex.exec(queryString)) {
            params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
        }
        return params;
    }
    

    useEffect(() => {
        const params = getQueryParams(location.search)

        if ( params?.code ) {
            handleVkResponse(params.code)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    useEffect(() => {
        return () => {
            removeHash()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className="overlay overlay--common active">
        <div className="overlay__bg"></div>
        <div className="modal modal--login opened">
            <button onClick={() => setShowLoginModal(false)} className="modal__close"></button>
            <div className="modal__name">
                {t('login.login')}
            </div>
            <form className="modal__form">
                <label className="modal__label">
                    Email
                    <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="modal__input"
                           placeholder={t('setting.email_title')}/>
                </label>
                <label className="modal__label">
                    {t('login.password')}
                    <input value={password} onChange={e => setPassword(e.target.value)} type="password"
                           className="modal__input" placeholder={t('setting.current_pass_placeholder')}/>
                </label>
                {validPassword.length > 0 && <p className={'red_text'}>{validPassword}</p>}
                <button onClick={(e) => LoginUser(e)} className="btn btn--black modal__btn">
                    {t('header.login')}
                </button>
            </form>
            
            <SocialLogin getUserData={getUserData}/>

            <a onClick={() => signUp()} href="#" className="modal__link modal__link--signup">
                {t('header.sign_up')}
            </a>
            <a onClick={() => forgotPass()} href="#" className="modal__link modal__link--password">
                {t('login.forgot_pass')}
            </a>
        </div>

    </div>
}

export default Login
