import React, { useEffect, useState } from 'react';
import videojs from 'video.js';
import VideoJS from '../VideoJS/VideoJS';
import { api_url } from '../../utils/utils';
import { addUserWatched } from '../../api/serials';
import './css/styles.css'
import { hiddenSubtitlesItem } from './js/functions';

export const VideoPlayer = ({setActiveSeason,activeSeason, serialData, episodes, series,setSeries}) => {

  const playerRef = React.useRef(null);
  const [season, setSeason] = useState(null)
  const [activeSeasonArrow, setActiveSeasonArrow] = useState(null)
  const [loading, setLoading] = useState(false);

  const [videoJsOptions, setVideoJsOptions] = useState({
    controls: true,
    responsive: true,
    fluid: true,
    playbackRates: [0.50, 0.60, 0.70, 0.80, 0.90, 1.00, 1.25, 1.50, 2.00],
    textTrackDisplay: {
      allowMultipleShowingTracks: true
    },
    html5: {
        nativeTextTracks: false,
        featuresNativeTextTracks: true,
    },
    textTrackSettings: false,
    enableSmoothSeeking: true,
    autoplay: true,
    playsinline: true,
    muted: true,
    preload: 'auto',
  })


  function processSeasons(season) {
    const seriesData = [];
      season.season_series.forEach(episode => {
        seriesData.push({
          name: episode.title,
          onClick: () => {
            setSeries(episode.episode_number)
          }
        });
    });

    return seriesData;
  }


  const updateSeasonPrev = ({seasons, seasonPrev, seasonNext, prevSeason}) => {
    seasonPrev.title = prevSeason ? `Season ${prevSeason.season_number}` : '';
    seasonPrev.seasonNumber = prevSeason ? prevSeason.season_number : 0 ;

    seasonPrev.onClick = (seasonNumber) => {
      let stepPrev = seasonNumber - 1
      const updateSeasonPrev = seasons.find(season => season.season_number === seasonNumber);
      const nextSeason = seasons.find(season => season.season_number === seasonNumber + 1);
      const prevSeriesData = processSeasons(updateSeasonPrev);

      seasonPrev.title =  stepPrev <= 0  ? `` : `Season ${stepPrev}`;
      seasonPrev.seasonNumber = stepPrev;

      updateSeasonNext({seasons, seasonPrev, seasonNext, nextSeason})
      setActiveSeasonArrow(nextSeason);
      setSeason({
        titleSeason: `Season ${stepPrev + 1}`,
        titleSeries: `Season ${stepPrev + 1}`,
        seriesData: prevSeriesData,
        seasonPrev,
        seasonNext
      }); 
    };
  };

  const updateSeasonNext = ({seasons, seasonPrev, seasonNext, nextSeason}) => {
    if (nextSeason) {
      seasonNext.title = `Season ${nextSeason.season_number}`;
      seasonNext.seasonNumber = nextSeason.season_number;
      
      seasonNext.onClick = (seasonNumber) => {
        let stepNext = seasonNumber + 1
        const updateSeasonNext = seasons.find(season => season.season_number === seasonNumber);
        const prevSeason = seasons.find(season => season.season_number === seasonNumber - 1);
        const nextSeriesData = processSeasons(updateSeasonNext);

        seasonNext.title = stepNext >= seasons.length + 1 ? `` : `Season ${stepNext}` ;
        seasonNext.seasonNumber = stepNext;

        updateSeasonPrev({seasons, seasonPrev, seasonNext, prevSeason})
        setActiveSeasonArrow(nextSeason);
        setSeason({
          titleSeason: `Season ${stepNext - 1}`,
          titleSeries: `Season ${stepNext - 1}`,
          seriesData: nextSeriesData,
          seasonPrev,
          seasonNext
        });
      };
    }
  };

  function setSeasonData(seasons, currentSeasonNumber) {
    const seasonPrev = {};
    const seasonNext = {};
    const nextSeason = seasons.find(season => season.season_number === currentSeasonNumber.season_number + 1);
    const prevSeason = seasons.find(season => season.season_number === currentSeasonNumber.season_number - 1);

    updateSeasonPrev({seasons, seasonPrev, seasonNext, prevSeason})
    updateSeasonNext({seasons, seasonPrev, seasonNext, nextSeason})

    return { seasonPrev, seasonNext };
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    const seasonSeries = activeSeason.season_series.find(({episode_number}) => episode_number === series)

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });

    player.on('playing', function() {
      setLoading(false)
    });

    player.on('ended', () => {
      setLoading(true)

      addUserWatched(seasonSeries.id)

      // Switch series
      const isNextSeries = activeSeason.season_series.some(({episode_number}) => episode_number === series + 1)

      if (isNextSeries) {
        setSeries((prev) => prev + 1)
      } else {
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    if (series !== undefined) {
      const seasonSeries = activeSeason.season_series.find(({episode_number}) => episode_number === series)
      if (seasonSeries) {
        const tracks = seasonSeries.episode_subtitles.map((subtitle) => ({
          // no /api/ because the URL was updated
          src: subtitle.subtitles_file,
          srclang: subtitle.language,
          label: subtitle.language,
        }))
        setVideoJsOptions(prev => ({
          ...prev,
          sources: [{
            // no /api/ because the URL was updated
            src:  api_url + `tv-shows/${seasonSeries.id}/get_streaming_video`,
            type: 'video/mp4'
          }],
          tracks
        }))

        // Hide subtitles that are not translated
        hiddenSubtitlesItem(tracks)
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodes, series])

  useEffect(() => {
    if (serialData.tv_show_seasons && activeSeasonArrow) {
      setSeasonData(serialData.tv_show_seasons, activeSeasonArrow)
    }

    // default
    if (!activeSeasonArrow) {
      const processedSeriesData = processSeasons(serialData.tv_show_seasons[0]);
      const { seasonPrev, seasonNext } = setSeasonData(serialData.tv_show_seasons, serialData.tv_show_seasons[0]);
      setSeason({
        titleSeason: `Season 1`,
        titleSeries: 'Series 1',
        seriesData: processedSeriesData,
        seasonPrev,
        seasonNext
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialData, activeSeasonArrow]);

  useEffect(() => {
    if (serialData && serialData.tv_show_seasons?.length) {
      setActiveSeasonArrow(serialData.tv_show_seasons[0])
    }
  }, [serialData]);


  return (
      <VideoJS 
        options={videoJsOptions}
        season={season}
        onReady={handlePlayerReady}
        loading={loading}
        serialId={serialData.id}/>
  );
}
