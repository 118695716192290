import {useEffect, useState} from "react";
import SerialCard from "../components/SerialCard";
import {getCountriesFilter, getGenresFilter, getSerials, getYearsFilter} from "../api/serials";
import {useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGlobalState } from "../hooks/store";


const Videos = ({favouriteSerials, userData,setFavouriteSerials,
                    getFavourites, getWatched , setWatchedSerials}) => {

    const {pathname, ...location} = useLocation();
    const { t,  i18n} = useTranslation();

    const token = Cookies.get('token');
    const [, setShowPaymentToast] = useGlobalState('showPaymentToast')

    const [lang, setLang] = useState()

    const [sortData] = useState(['title', 'rating', 'year'])
    const [showSortFilter, setShowSortFilter] = useState('')
    const [sortFilter, setSortFilter] = useState('rating')


    const [genres, setGenres] = useState([])
    const [showGenresFilter, setShowGenresFilter] = useState('')
    const [genresFilter, setGenresFilter] = useState(t('video.choose_option'))

    const [countries, setCountries] = useState([])
    const [showCountryFilter, setShowCountryFilter] = useState('')
    const [countryFilter, setCountryFilter] = useState(t('video.choose_option'))

    const [channelFilter, setChannelFilter] = useState(t('video.choose_option'))

    const [years, yearsFilter] = useState([])
    const [showYearFilter, setShowYearFilter] = useState('')
    const [yearFilter, setYearFilter] = useState(t('video.choose_option'))

    const [serials, setSerials] = useState([])
    const [filteredSerials, setFilteredSerials] = useState([])


    i18n.on('languageChanged', lang => {
        setLang(lang)
    });

    const addFilters = () => {
        getCountriesFilter(setCountries, token)
        getGenresFilter(setGenres, token)
        getYearsFilter(yearsFilter, token)
    }
    const handleLoadMore = () => {
        getSerials({setSerials, next: serials.next, serials: serials.results}, token, sortFilter, genresFilter, countryFilter, channelFilter, yearFilter,'')
    }

    const handleDropdownToggle = (dropdown) => {
        setShowSortFilter(dropdown === 'sort' ? !showSortFilter : false);
        setShowGenresFilter(dropdown === 'genres' ? !showGenresFilter : false);
        setShowCountryFilter(dropdown === 'country' ? !showCountryFilter : false);
        setShowYearFilter(dropdown === 'year' ? !showYearFilter : false);
    };

    useEffect(() => {
        setChannelFilter(t('video.choose_option'))
        setCountryFilter(t('video.choose_option'))
        setYearFilter(t('video.choose_option'))
        setGenresFilter(t('video.choose_option'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    useEffect(() => {
        console.log('token 1', token)
        if (token) {
            getSerials(setSerials, token, sortFilter, genresFilter, countryFilter, channelFilter, yearFilter,'')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        getFavourites(token, setFavouriteSerials)

        if (location.hash === '#successful-payment') {
            setShowPaymentToast({show: true, type: 'success' })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition")
      if (savedScrollPosition) {
        window.scrollTo(0, Number(savedScrollPosition));
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredSerials]);

    useEffect(() => {
        addFilters()
        setFilteredSerials(serials.results)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serials]);

    useEffect(() => {
        getSerials({setSerials}, token, sortFilter, genresFilter, countryFilter, channelFilter, yearFilter,'')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortFilter, genresFilter, countryFilter, channelFilter, yearFilter,]);


    return <section className="videos">
        <div className="container">
            <div className="videos__filter filter">
                <div className="filter__header">
                    <div className="filter__title">{t('video.title')}</div>
                    <div className="filter__total">
                        {t('video.total')}: <span>{serials?.count} {t('video.video')}</span>
                    </div>
                </div>
                <div className="filter__body">
                    <label className={`filter__label ${showSortFilter ? 'active' : ''}`}>
                       {t('video.sort_by_title')}
                       <div onClick={() => handleDropdownToggle("sort")} className="custom-select ">
                           <div className={showSortFilter ? 'select-header opened' : 'select-header'}><span
                               className="selected-option">{sortFilter === t('video.choose_option') ? t('video.choose_option') : t(`video.sort_by.${sortFilter}`)  }</span><i
                               className="arrow-icon"></i></div>
                           <ul className={showSortFilter ? 'options-list opened' : 'options-list'}>
                               <li onClick={() => setSortFilter(t('video.choose_option'))}>{t('video.choose_option')}</li>
                               {sortData.map(item => <li onClick={() => setSortFilter(item)}>{t(`video.sort_by.${item}`)}</li>)}
                           </ul>
                       </div>
                    </label>
                    <label className={`filter__label ${showGenresFilter ? 'active' : ''}`}>
                        {t('video.genres')}
                        <div onClick={() => handleDropdownToggle("genres")} className="custom-select ">
                            <div className={showGenresFilter ? 'select-header opened' : 'select-header'}><span
                                className="selected-option">{genresFilter === t('video.choose_option') ? t('video.all_genres') : genresFilter }</span><i
                                className="arrow-icon"></i></div>
                            <ul className={showGenresFilter ? 'options-list opened' : 'options-list'}>
                                <li onClick={() => setGenresFilter(t('video.choose_option'))}>{t('video.all_genres')}</li>
                                {genres.map((item, i) => <li  key={i} onClick={() => setGenresFilter(item.title)}>{item.title}</li>)}
                            </ul>
                        </div>
                    </label>
                    <label className={`filter__label ${showCountryFilter ? 'active' : ''}`}>
                        {t('video.country')}
                        <div onClick={() => handleDropdownToggle("country")} className="custom-select ">
                            <div className={showCountryFilter ? 'select-header opened' : 'select-header'}><span
                                className="selected-option">{countryFilter === t('video.choose_option') ? t('video.all_countries') : countryFilter } </span><i
                                className="arrow-icon"></i></div>
                            <ul className={showCountryFilter ? 'options-list opened' : 'options-list'}>
                                <li onClick={() => setCountryFilter(t('video.choose_option'))}>{t('video.all_countries')}</li>
                                {countries.map((item, i) => <li  key={i}
                                    onClick={() => setCountryFilter(item.name)}>{item.name}</li>)}
                            </ul>
                        </div>
                    </label>
                    {/* <label className="filter__label">
                        {t('video.channel')}
                        <div onClick={() => setShowChannelFilter(!showChannelFilter)} className="custom-select ">
                            <div className={showChannelFilter ? 'select-header opened' : 'select-header'}><span
                                className="selected-option">{channelFilter}</span><i
                                className="arrow-icon"></i></div>
                            <ul className={showChannelFilter ? 'options-list opened' : 'options-list'}>
                                <li onClick={() => setChannelFilter(t('video.choose_option'))}>{t('video.choose_option')}</li>
                                {channels.map((item, i) => <li  key={i} onClick={() => setChannelFilter(item)}>{item}</li>)}
                            </ul>
                        </div>
                    </label> */}
                    <label className={`filter__label ${showYearFilter ? 'active' : ''}`}>
                        {t('video.year')}
                        <div onClick={() => handleDropdownToggle("year")} className="custom-select ">
                            <div className={showYearFilter ? 'select-header opened' : 'select-header'}><span
                                className="selected-option">{yearFilter === t('video.choose_option') ? t('video.all_years') : yearFilter }</span><i
                                className="arrow-icon"></i></div>
                            <ul className={showYearFilter ? 'options-list opened' : 'options-list'}>
                                <li onClick={() => setYearFilter(t('video.choose_option'))}>{t('video.all_years')}</li>
                                {years.map((item, i) => <li  key={i} onClick={() => setYearFilter(item)}>{item}</li>)}
                            </ul>
                        </div>
                    </label>
                </div>
            </div>
            {
                filteredSerials?.length ? (
                    <InfiniteScroll
                        pageStart={0}
                        dataLength={filteredSerials.length}
                        next={handleLoadMore}
                        hasMore={filteredSerials.length < serials.count || false}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                        <ul className="videos__list videos-list" >
                            {filteredSerials.map((item) => {
                                return (
                                <SerialCard  
                                    key={item.id}
                                    setSerials={setSerials}
                                    serial={item}
                                    setWatchedSerials={setWatchedSerials}
                                    favouriteSerials={favouriteSerials}
                                />)
                            })}
                        </ul>
                    </InfiniteScroll>
                ) : <div>Loading…</div>
            }
        </div>
    </section>
}

export default Videos
